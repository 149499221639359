import React, { useState, useEffect } from "react";
import "./style.css";
import Icon from "../../assets/icons/icons";

const CustomDropdown = ({
  options,
  defaultOption,
  textColor,
  icon,
  className,
  onChange, // Make onChange optional
  marginArrow,
  textStyle,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(defaultOption);
  const [optionsList, setOptionsList] = useState(options);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    setSelectedOption(defaultOption);
  }, [defaultOption]);

  useEffect(() => {
    setOptionsList(options);
  }, [options]);

  const handleOptionClick = (option) => {
    setSelectedOption(option.label);
    setIsOpen(false);
    if (onChange) {
      onChange(option.value);
    }
  };

  return (
    <div className={`custom-dropdown ${className}`}>
      <div
        className="selected-option"
        onClick={toggleDropdown}
        style={{ color: textColor }}
      >
        <h6 style={textStyle}>{selectedOption}</h6>
        <Icon
          name={icon.name}
          fill={icon.fill}
          size={icon.size}
          style={{
            marginTop: "7px",
            marginBottom: "0px",
            marginRight: marginArrow,
          }}
        />
      </div>

      {isOpen && (
        <div className="options">
          {optionsList.map((option, index) => (
            <div
              key={index}
              className="option"
              onClick={() => handleOptionClick(option)}
              style={{ color: textColor }}
            >
              {option.label}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default CustomDropdown;
