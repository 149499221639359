// Import required modules
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import axios from "axios";

// Initialize i18n with configurations
i18n
  .use(initReactI18next)
  .init({
    lng: 'en', // Default language
    fallbackLng: 'en', // Fallback language if translation is missing
    resources: {
      en: {
        translation: {} // Empty initial translation resources
      },
      ar: {
        translation: {} // Empty initial translation resources for Arabic
      }
    },
    interpolation: {
      escapeValue: false // Allows using HTML tags in translation strings
    }
  });

// Function to load translations from the API
export async function loadTranslations() {
  try {
    const response = await axios.get(`${process.env.REACT_APP_BACKEND}/api/translations`);
  
    if (response.data && response.data.data) {
      const data = response.data.data;
      const enTranslations = {};
      const arTranslations = {};
      data.forEach((item) => {
        enTranslations[item.attributes.key] = item.attributes.en.toString();
        arTranslations[item.attributes.key] = item.attributes.ar.toString();
      });

      // Add translation resources to i18n for English and Arabic
      i18n.addResourceBundle('en', 'translation', enTranslations);
      i18n.addResourceBundle('ar', 'translation', arTranslations);

      await i18n.isInitialized;
      i18n.changeLanguage('en');
    } else {
      console.error('API response has no data');
    }
  } catch (error) {
    console.error('Error fetching translations:', error.message);
  }
}

export default i18n;
