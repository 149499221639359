import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import LogoIcon from "../../assets/icons/Group.svg";
import Icon from "../../assets/icons/icons";
import { Link, useLocation } from "react-router-dom";
import LanguageSwitch from "../../languageSwitch";
import translations from "../../translations/translation";
import { useTranslation } from "react-i18next";
import { theme } from "../../theme";
import _ from "lodash";
const Header = () => {
  const [headerBackground, setHeaderBackground] = useState("");
  // const [showSearchTab, setShowSearchTab] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);

  const { i18n } = useTranslation();
  const location = useLocation();
  const menuRef = useRef(null);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [location.pathname]);

  // useEffect(() => {
  //   const handleClickOutside = (event) => {
  //     if (menuRef.current && !menuRef.current.contains(event.target)) {
  //       // setMenuOpen(false);
  //     }
  //   };

  //   document.addEventListener("mousedown", handleClickOutside);

  //   return () => {
  //     document.removeEventListener("mousedown", handleClickOutside);
  //   };
  // }, []);

  useEffect(() => {
    if (location.pathname === "/") {
      setHeaderBackground("transparent");
    } else {
      setHeaderBackground(theme.secondary);
    }
  }, [location.pathname]);

  const handleScroll = () => {
    console.log("dfgdfgdfgdfg", location.pathname);

    if (window.scrollY <= 200 && location.pathname === "/") {
      setHeaderBackground("transparent");
    } else {
      setHeaderBackground(theme.secondary);
    }
  };

  // const search = () => {
  //   console.log('ssdfsdfsfd');
  //   // setShowSearchTab(true);
  // };
  // Geting the page name from
  const transformedPath = location.pathname
    .replace(/\//g, ">")
    .replace(/%20/g, " ")
    .replace(/-/g, " ");

  const style = {
    textTransform: "capitalize",
    fontSize: "10px",
    fontWeight: "lighter",
    color: "#808080",
  };

  const splitPath = transformedPath.split(">");
  const firstPart = _.camelCase(splitPath[1]);
  const lastPart = _.camelCase(splitPath[splitPath.length - 1]);
  const redColorStyle = {
    ...style,
    color: lastPart !== firstPart ? theme.primary : "",
  };
  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };
  // Forwarded ref for SearchBar
  // const SearchBar = React.forwardRef((props, ref) => {
  //   return (
  //     <SearchBarContainer contenteditable='true' ref={ref}></SearchBarContainer>
  //   );
  // });

  // Log translation for firstPart
  console.log("firstPart", translations[i18n.language][firstPart]);

  return (
    <>
      {/* Header */}
      <HeaderContainer style={{ backgroundColor: headerBackground }}>
        <StyledLink to="/" onClick={toggleMenu}>
          <Logo>
            <img
              src={LogoIcon}
              style={{ width: "144px", height: "43px" }}
              alt={"This is logo header"}
            />
          </Logo>
        </StyledLink>
        {/* Menu Button */}
        <MenuButton onClick={toggleMenu}>
          <span></span>
          <span></span>
          <span></span>
        </MenuButton>
        {/* Menu Container */}

        <MenuContainer
          style={{ backgroundColor: headerBackground }}
          showMenu={menuOpen}
          ref={menuRef}
        >
          <Nav>
            {/* Navigation Items */}
            <NavItem>
              <StyledLink to="/news" onClick={toggleMenu}>
                {translations[i18n.language].headers.h1}
              </StyledLink>
            </NavItem>
            <NavItem>
              <StyledLink to="/board-members" onClick={toggleMenu}>
                {translations[i18n.language].headers.h2}
              </StyledLink>
            </NavItem>
            <NavItem>
              <StyledLink to="/contact-us" onClick={toggleMenu}>
                {translations[i18n.language].headers.h3}
              </StyledLink>
            </NavItem>
          </Nav>

          {/* Language Switch */}
          <LanguageSwitchContainer>
            <LanguageSwitch onClick={toggleMenu} />
          </LanguageSwitchContainer>
        </MenuContainer>
      </HeaderContainer>

      {/* Breadcrumb */}
      {/* {location.pathname !== "/" && location.key !== "default" && (
        <BreadCrumb>
          <h6
            style={{
              color: theme.primary,
              fontSize: "10px",
              fontWeight: "lighter",
              display: "flex",
            }}
          >
            {i18n.language === "en" ? "Home" : "الصفحة الرئيسية"}
            <Icon
              name="arrowDown"
              fill={theme.primary}
              size={12}
              style={{
                transform:
                  i18n.language === "en" ? "rotate(270deg)" : "rotate(90deg)",
                marginLeft: "2px",
                marginTop: "4px",
              }}
            />
          </h6>
          <h6 style={style}>
            <span style={redColorStyle}>
              {translations[i18n.language][firstPart]}
            </span>

            {lastPart !== firstPart && (
              <>
                <Icon
                  name="arrowDown"
                  fill={theme.primary}
                  size={12}
                  style={{
                    transform:
                      i18n.language === "en"
                        ? "rotate(270deg)"
                        : "rotate(90deg)",
                    marginLeft: "2px",
                  }}
                />
                <span>{lastPart}</span>
              </>
            )}
          </h6>
        </BreadCrumb>
      )} */}
    </>
  );
};

// Styled components
const HeaderContainer = styled.div`
  position: fixed;
  font-family: "ABeeZee", sans-serif !important;

  top: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  transition: background-color 0.3s ease-in-out;
  color: ${theme.white};
  z-index: 100;
  box-sizing: border-box;
  overflow-x: hidden;
  padding-left: 72px;
  padding-right: 72px;
  justify-content: space-between;

  @media (max-width: 1000px) {
    padding: 12px 24px;
  }
`;

const BreadCrumb = styled.div`
  margin-top: 10%;
  margin-bottom: 20px;
  display: flex;
  padding: 0 72px;

  @media (max-width: 768px) {
    padding: 0 24px;
    margin-top: 20%;
  }
`;

const MenuButton = styled.button`
  display: none;

  @media (max-width: 768px) {
    display: block;
    position: relative;
    width: 25px;
    height: 20px;
    background-color: transparent;
    border: none;
    cursor: pointer;
    z-index: 101;

    span {
      position: absolute;
      width: 100%;
      height: 2px;
      background-color: ${theme.white};
      border-radius: 2px;
      transition: all 0.3s ease-in-out;

      &:nth-child(1) {
        top: 0;
      }

      &:nth-child(2) {
        top: 50%;
        transform: translateY(-50%);
      }

      &:nth-child(3) {
        bottom: 0;
      }
    }
  }
`;

const MenuContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  color: ${theme.white};
  width: 100%;
  overflow: hidden;

  @media (max-width: 768px) {
    display: ${({ showMenu }) => (showMenu ? "flex" : "none")};
    flex-direction: column;
    position: fixed;
    align-items: flex-start;
    top: 70px;
    right: 0;
    transition: background-color 0.3s ease-in-out;
    padding: 20px 3px;
    z-index: 999;
    // width: 200px;
  }
`;

const NavItem = styled.div`
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.307px;
  color: ${theme.white};
  text-decoration: none;
  margin: 10px;

  &:hover {
    text-decoration: underline;
  }
  &:focus {
    text-decoration: underline;
  }
  @media (min-width: 768px) {
    margin: 0 16px;
  }
`;
const Logo = styled.div`
  flex: 1;
`;

const Nav = styled.nav`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  text-decoration: none !important;
  flex: 1;
  margin: 20px 0;
  @media (max-width: 768px) {
    align-items: flex-start;

    flex-direction: row;
    margin: 0;
    flex-direction: column;
  }
`;

// const SearchAndLanguageContainer = styled.div`
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   justify-content: center;
//   margin-bottom: 20px;

//   @media (min-width: 768px) {
//     flex-direction: row;
//     margin: 0 80px;
//   }
// `

// const Search = styled.div`
//   display: flex;
//   align-items: center;
//   color: #fff;
//   margin-bottom: 10px;

//   @media (min-width: 768px) {
//     margin-bottom: 0;
//     margin-right: 20px;
//   }
// `

const LanguageSwitchContainer = styled.div`
  display: flex;
  align-items: center;
  color: ${theme.white};
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.307px;
  
  @media (max-width: 768px) {
    margin-left: 10px;
    margin-top: 10px;
    align-items: flex-start;

}  }
`;

const StyledLink = styled(Link)`
  text-decoration: none !important;
  color: ${theme.white};

  &:hover {
    text-decoration: underline;
  }

  &:focus {
    text-decoration: underline;
  }
`;
// const SearchBarContainer = styled.div`
//   position: fixed;
//   top: 100px; /* Adjust this value to leave space below the header */
//   left: 0;
//   right: 0;
//   background: rgba(255,255,255, .3);
//   padding: 20px;
//   z-index: 9999;
//   width: 75%;
//   margin: 0 auto;

// color:#000;
//   border-radius:10px
// }
// `
export default Header;
