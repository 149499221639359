import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import fileReducer from './fileSlice.js';

const rootReducer = combineReducers({
  form: formReducer,
  app: combineReducers({
    file: fileReducer,
  }),
});

export default rootReducer;