import React from "react";
import styled from "styled-components";
import Twitter from "../../assets/icons/twitter2.svg";
import LinkedIn from "../../assets/icons/Linkedin.svg";
import Icon from "../../assets/icons/icons";
// import Logo from "../../assets/image/piflogo.png";
import Logo from "../../assets/image/PIF-sub-english.svg";
import LogoEn from "../../assets/image/PIF-sub-arabic.svg";

import BridgeStyle from "../../assets/icons/footer-style.svg";
import BridgeStyleMobile from "../../assets/icons/footer.svg";
import translations from "../../translations/translation";
import { useTranslation } from "react-i18next";
import { theme } from "../../theme";

const Footer = () => {
  const { i18n } = useTranslation();

  return (
    <Container>
      <FooterContainer>
        <LeftSide>
          {i18n.language === "en" ? (
            <img
              src={LogoEn}
              style={{ width: "210px" }}
              alt="This used for Logo"
            />
          ) : (
            <img
              src={Logo}
              style={{ width: "210px" }}
              alt="This used for Logo"
            />
          )}
        </LeftSide>
        <RightSide>
          <FooterText>{translations[i18n.language].footers.f1}</FooterText>
        </RightSide>
      </FooterContainer>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  background-color: ${theme.secondary};
  height: 170px;
`;

const FooterContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0 65px;
  color: ${theme.white};
  bottom: 0;
  margin-left: 72px;
  margin-right: 72px;
  height: 100%;
  position: relative; // Remove quotes here
  @media (max-width: 768px) {
    padding: 12px 30px;
    font-size: 10px;
    margin-left: 10px;
    margin-right: 10px;
  }
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
`;

const LeftSide = styled.div`
  width: 300px;
  padding-bottom: 20px;
  img {
    width: 157px;
    height: 38px;
  }
  @media (max-width: 768px) {
    width: 217px;
    flex: 1;

    img {
      width: 100px;
      height: 33px;
      margin-right: 7px;
    }
  }
`;

// const Middle = styled.div`
//   text-align: left;
//   padding-bottom: 20px;
//   @media (max-width: 768px) {
//     margin-top: 92px;
//     width: 120px;
//     font-size: 8px;
//   }
// `;

const RightSide = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding-bottom: 20px;
  @media (max-width: 768px) {
    flex-direction: column;
    font-size: 8px;
    align-items: flex-start;
  }
`;

const FooterText = styled.p`
  margin: 0;
`;

const SocialMediaLink = styled.a`
  color: ${theme.white};
  font-size: 1.5rem;
  margin-left: ${(props) => (props.language === "en" ? "15px" : "0")};

  display: inline-flex;
  align-items: center;
  @media (max-width: 768px) {
    margin-left: 0;
  }
`;

const BridgeStyleImage = styled.img`
  position: absolute; // Position absolutely within the container
  bottom: 0; // Stick to the bottom of the container
  width: 100%;
  left: 0;
`;

export default Footer;
