const translations = {
  en: {
    sorry: "  Sorry ...the page you are looking for does not exist or has been",
    wrong: "Opps!! Something went wrong, please try again.",
    topic1: "Investment opportunity",
    topic2: "General Inquiry",
    topic3: "Job Opportunity",
    invest_q: "Are you interested to invest as a?",
    company: "Company",
    companies: "Companies Across the Region ",
    individual: "Individual",
    board_members: "Leadership",
    read_more: "Read More",
    recent_art: "Recent Articles ",
    related_art: "Related Articles",
    playVideo: "Play Video",
    share: "Share",
    published: "Published On",
    search: "Search",
    contactUs: "Contact Us",
    contact: "Contact Info",
    select: "Select",
    sectors: "Sectors",
    email: "Email",
    invalidEmail: "Invalid email address",
    requiredFirstName: "First name is required",
    requiredLastName: "Last name is required",
    requiredEmail: "Email is required",
    requiredPhone: "Phone is required",
    requiredTopic: "Topic is required",
    requiredSubject: "Subject is required",
    requiredMessage: "Message is required",
    phone: "Phone Number",
    location: "Location",
    scrollDown: "Scroll Down",
    company_info: "Company Information",
    fin_info: "Financial  Information",
    personal_info: "Personal Information",
    send: "Submit",
    isSend: "Sending...",
    add_file: "Drag you file here, or Browse",
    suuport: "Supports: JPG, PNG, PDF",
    invest_rel: "Investors Relationships ",
    our_portfolios: "Our Portfolios",
    about: "Who We Are",
    descendingOrder: "Descending Order",
    ascendingOrder: "Ascending Order ",
    mostRecent: "Most Recenet",
    sort: "Sort By",
    news: "News",
    boardMembers: "Board Members",
    news_insight: "News and Insight",
    company_form: {
      q1: "Company Name",
      q2: "Email of company representative ",
      q3: "Phone number of company representative",
      q4: "Company website",
      q5: "Company sector",
      q6: "Location of operations",
      q7: "Annual revenue",
      q8: "Majority of revenue generated in USD and/or Euro",
      q9: "Significant minority shareholding for SEIC while co-control and control will also be considered, subject to partnering options",
      q10: "Preference for primary, growth capital",
      q11: "Demonstrable liquidity or exit options",
      q12: "Target must have clear Egyptian component  (HQ and/or asset base and/or revenues).",
      q13: "3 years of audited financials for the investee company or its parent Company name",
    },
    individual_form: {
      q1: "Full Name",
      q2: "Email",
      q3: "Nationality",
      q4: "Phone number ",
      q5: "Annual Income",
      q6: "Income Resources",
      q7: "Which sectors are you interested in?",
      q8: "3 years of audited ersonal financial statements Company name",
    },
    place_holder: {
      p1: "First Name",
      p2: "Last Name",
      p3: "Email",
      p4: "Phone",
      p5: "Topic",
      p6: "Subject",
      p7: "Message",
    },
    our_partner: "Our Partners",
    invest: "Invest Now",
    headers: {
      h1: "News and Insight",
      h2: "Board Members",
      h3: "Contact Us",
    },
    footers: {
      f1: "© 2024 All rights reserved",
      f2: "Follow our official Social Media",
    },
  },
  ar: {
    sorry: "  Sorry ...the page you are looking for does not exist or has been",
    wrong: "اوبس!! حدث خطأ، الرجاء المحاولة مرة أخرى.",
    select: "اختر",
    topic1: "فرصة استثمار",
    topic2: "استفسار عام",
    topic3: "فرصة عمل",
    companies: "الشركات في المنطقة",
    boardMembers: "اعضاء المجلس",
    search: "ابحث",
    sort: "الترتيب حسب",
    descendingOrder: "تنازليا",
    ascendingOrder: "تصاعديا ",
    mostRecent: "أحدث الأخبار",
    invest_q: "هل تريد الاستثمار",
    send: "ارسل",
    isSend: "ارسال...",
    company: "شركات",
    contactUs: "تواصل معنا",
    individual: "افراد",
    board_members: "القادة",
    playVideo: "عرض الفيديو",
    scrollDown: "`حرك للأسفل",
    recent_art: "آخر المقالات",
    related_art: "المقالات المرتبطة",
    share: "مشاركة",
    published: "تم النشر",

    read_more: "المزيد",
    news_insight: "الاخبار",
    company_info: "معلومات الشركة",
    fin_info: "المعلومات المالية",
    personal_info: "المعلومات الشخصية",
    add_file: "اسحب ملفك إلى هنا، أو تصفح",
    suuport: "يدعم: JPG, PNG, PDF",
    invest_rel: "علاقات المستثمرين ",
    contact: "بيانات التواصل",
    email: "الايميل",
    invalidEmail: "البريد الالكتروني غير صالح",
    requiredFirstName: "الاسم الأول مطلوب",
    requiredLastName: "اسم العائلة مطلوب",
    requiredEmail: "الايميل مطلوب",
    requiredPhone: "رقم الجوال مطلوب",
    requiredTopic: "النوع مطلوب",
    requiredSubject: "الموضوع مطلوب",
    requiredMessage: "الرسالة مطلوبة",
    phone: "رقم الجوال",
    location: "الموقع",
    sectors: "القطاعات",
    news: "الاخبار",
    company_form: {
      q1: "اسم الشركة",
      q2: "ايميل ممثل الشركة ",
      q3: "رقم جوال ممثل الشركه",
      q4: "موقع الشركه",
      q5: "قطاع الشركة",
      q6: "موقع العمليات",
      q7: "الإيرادات السنوية",
      q8: "غالبية الإيرادات المتولدة بالدولار الأمريكي و / أو اليورو",
      q9: " سيتم أيضا النظر في حصة أقلية كبيرة في SEIC بينما سيتم النظر في السيطرة والسيطرة المشتركة ، رهنا بخيارات الشراكة",
      q10: "الأفضلية لرأس المال الأساسي والنمو",
      q11: "سيولة يمكن إثباتها أو خيارات خروج",
      q12: "يجب أن يكون الهدف مكونا مصريا واضحا (المقر الرئيسي و / أو قاعدة الأصول و / أو الإيرادات).",
      q13: "3 سنوات من البيانات المالية المدققة للشركة المستثمر فيها أو اسم الشركة الأم",
    },
    individual_form: {
      q1: "الاسم كامل",
      q2: "الايميل",
      q3: "الجنسية",
      q4: "رقم الجوال ",
      q5: "الدخل السنوي",
      q6: "موارد الدخل",
      q7: "ما هي القطاعات التي تهتم بها؟",
      q8: "3 سنوات من البيانات المالية المدققة اسم الشركة",
    },
    place_holder: {
      p1: "الاسم الأول",
      p2: "اسم العائلة",
      p3: "الايميل",
      p4: "رقم الجوال",
      p5: "نوع",
      p6: "موضوع",
      p7: "رسالة",
    },
    our_partner: "شركاؤنا",
    our_portfolios: "محافظنا",
    about: "من نحن",
    invest: "استثمر الان",
    headers: {
      h1: "المركز الإعلامي",
      h2: "القيادة",
      h3: "تواصل معنا",
    },
    footers: {
      f1: "© 2024 جميع الحقوق محفوظة",
      f2: "تابعنا على وسائل التواصل الاجتماعي",
    },
  },
};

export default translations;
