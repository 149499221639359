// fileSlice.js

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  file: null,
};

const fileSlice = createSlice({
    name: 'file',
    initialState,
    reducers: {
      uploadFile: (state, action) => {
        state.file = action.payload;
      },
    },
  });

  console.log("sdfsdf22",fileSlice.actions);

export const { uploadFile } = fileSlice.actions;

export default fileSlice.reducer;