import React, { useState, useMemo, memo, useEffect } from "react";
import { Field, reduxForm } from "redux-form";
import { connect } from "react-redux";
import axios from "axios";
import ReCAPTCHA from "react-google-recaptcha";
import translations from "../../translations/translation";
import { useTranslation } from "react-i18next";
import Shadow from "../../assets/icons/contacts.svg";
import "./style.css";
import { useNavigate } from "react-router-dom";
import PatternOne from "../../assets/icons/pattern2.svg";
import CustomDropdown from "../../Component/CustomDropdown";
import { theme } from "../../theme";

let validateMessages;

const Investors = (props) => {
  const { handleSubmit } = props;
  const [recaptchaToken, setRecaptchaToken] = useState(null);
  const { i18n } = useTranslation();
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
  const [data, setData] = useState();
  const [error, setError] = useState(false);
  const [errorContact, setErrorContact] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  validateMessages = translations[i18n.language];
  const navigate = useNavigate();

  useEffect(() => {
    getContactInfo();
  }, []);

  // Input field Componenet
  const renderField = useMemo(
    () =>
      memo(({ input, label, type, placeholder, meta: { touched, error } }) => (
        <div className="inpu-field">
          <input
            {...input}
            type={type}
            id={input.name}
            className={`form-input${touched && error ? " has-error" : ""}`}
            placeholder={placeholder}
            style={{ direction: i18n.language === "en" ? "ltr" : "rtl" }}
          />
          {touched && error && <p className="error-message">{error}</p>}
        </div>
      )),
    []
  );
  // Text Area Componenet

  const renderTextArea = useMemo(
    () =>
      memo(({ input, label, type, placeholder, meta: { touched, error } }) => (
        <div style={{ width: "100%" }}>
          <textarea
            {...input}
            id={input.name}
            className={`form-text`}
            placeholder={placeholder}
          />
          {touched && error && <p className="error-message">{error}</p>}
        </div>
      )),
    []
  );
  // DropDown Componenet

  const renderDropDown = useMemo(
    () =>
      memo(({ input, label, placeholder, meta: { touched, error } }) => (
        <div className="inpu-field">
          <CustomDropdown
            {...input} // Pass input props to CustomDropdown
            options={[
              {
                label: translations[i18n.language].topic1,
                value: translations[i18n.language].topic1,
              },
              {
                label: translations[i18n.language].topic2,
                value: translations[i18n.language].topic2,
              },
              {
                label: translations[i18n.language].topic3,
                value: translations[i18n.language].topic3,
              },
            ]}
            defaultOption={placeholder}
            arrowIcon="arrowDown"
            arrowColor={theme.primary}
            icon={{ name: "arrowDown", fill: theme.primary, size: 20 }}
            className="test2222"
            textStyle={{ marginTop: "24px", marginLeft: "10px" }}
          />
          {touched && error && <p className="error-message">{error}</p>}
        </div>
      )),
    [] // Include options in the dependency array
  );

  const submitForm = async (formData, recaptchaToken) => {
    if (!recaptchaToken) {
      // Captcha not yet clicked, do not submit the form
      return;
    }
    // Start loading This to show in the button that the Form is on process
    setIsSubmitting(true);

    const requestData = new FormData();
    debugger
    const selectedTopic = formData.Topic;

    requestData.append("data", JSON.stringify(formData));
    requestData.append("recaptcha_token", recaptchaToken);
    requestData.append("topic", selectedTopic);

    try {
      await axios.post(
        `${process.env.REACT_APP_BACKEND}/api/company-investors`,
        requestData
      );

      setIsSubmitting(false);
      navigate("/");
    } catch (error) {
      console.error(error);
      setError(true);
      setIsSubmitting(false);
    }
  };

  const handleRecaptchaChange = (token) => {
    setRecaptchaToken(token);
    // Enable the submit button if Captacha clicked
    setIsSubmitDisabled(false);
  };

  // This to get Contact info
  const getContactInfo = () => {
    axios
      .get(`${process.env.REACT_APP_BACKEND}/api/contact-info?populate=*`)
      .then((response) => {
        setData(response.data.data);
      })
      .catch((error) => {
        // setIsLoading(true)
        setErrorContact(true);
        console.log(error);
      });
  };

  return (
    <div className="my-form-container">
      <h2 style={{ marginBottom: "20px", fontStyle: "italic" }}>
        {translations[i18n.language].contactUs}
      </h2>

      <div
        className="container"
        style={{
          borderRadius: "3px",
          border: "1px solid #F4F4F4",
          backgroundColor: "var(--White, #FFF)",
          boxShadow: "4px 5px 12px 0px rgba(237, 237, 237, 0.15)",
          padding: "40px 10px",
        }}
      >
        <form
          onSubmit={handleSubmit((formData) =>
            submitForm(formData, recaptchaToken)
          )}
          style={{
            display: "flex",
            flexDirection: "column",
            flex: 2,
          }}
        >
          <div className="my-form-field-container">
            <Field
              name="First_name"
              component={renderField}
              type="text"
              placeholder={translations[i18n.language].place_holder.p1}
            />
            <Field
              name="Last_name"
              component={renderField}
              type="text"
              placeholder={translations[i18n.language].place_holder.p2}
            />
          </div>
          <div className="my-form-field-container">
            <Field
              name="Email"
              component={renderField}
              type="email"
              placeholder={translations[i18n.language].place_holder.p3}
            />
            <Field
              name="Phone"
              component={renderField}
              type="number"
              placeholder={translations[i18n.language].place_holder.p4}
            />
          </div>
          <div className="my-form-field-container">
            <Field
              name="Topic"
              component={renderDropDown}
              type="dropDown"
              placeholder={translations[i18n.language].place_holder.p5}
            />
            <Field
              name="Subject"
              component={renderField}
              type="text"
              placeholder={translations[i18n.language].place_holder.p6}
            />
          </div>
          <div className="my-form-field-container">
            <Field
              name="Message"
              component={renderTextArea}
              type="textarea"
              rows={18} // Set the number of rows if needed
              placeholder={translations[i18n.language].place_holder.p7}
            />
          </div>

          <ReCAPTCHA
            sitekey="6LeqvNYpAAAAAOPwSF3xa-xfV6zHU91BGG9nKNqG"
            onChange={handleRecaptchaChange}
            style={{ margin: "1rem 0", width: "100%" }}
          />

          <div className="form-submit-button-container">
            <button
              type="submit"
              className="form-submit-button"
              style={{ opacity: isSubmitDisabled || isSubmitting ? "0.4" : "" }}
              disabled={isSubmitDisabled || isSubmitting}
            >
              {isSubmitting
                ? translations[i18n.language].isSend
                : translations[i18n.language].send}
            </button>{" "}
            {error && (
              <h6 style={{ color: "red", marginTop: 0 }}>
                something went wrong pleas try again
              </h6>
            )}
          </div>
        </form>

        {/* <div className="contact-container">
          {errorContact ? (
            <h4>something went wrong pleas try again</h4>
          ) : (
            <>
              <h2
                style={{
                  fontSize: "20px",
                  fontWeight: "500",
                  lineHeight: "normal",
                  textTransform: "capitalize",
                }}
              >
                {translations[i18n.language].contact}
              </h2>
              <div style={{ padding: "20px 0" }}>
                <h3 className="titles">{translations[i18n.language].email}</h3>
                <h3 className="values">{data?.attributes?.email}</h3>
              </div>
              <div style={{ padding: "20px 0" }}>
                <h3 className="titles">{translations[i18n.language].phone}</h3>
                <h3 className="values">{data?.attributes?.phone}</h3>
              </div>
              <div style={{ padding: "20px 0" }}>
                <h3 className="titles">
                  {translations[i18n.language].location}
                </h3>
                <h3 className="values">
                  {i18n.language === "en"
                    ? data?.attributes?.location
                    : data?.attributes?.location_ar}
                </h3>
              </div>
            </>
          )}
        </div> */}
      </div>
      {/* <img
  src={PatternOne}
  className="pattern-one-image"
  alt='image'
/> */}
    </div>
  );
};

//Validation
const validate = (values) => {
  const errors = {};
  // const requiredFields = [
  //   "First_name",
  //   "Last_name",
  //   "Email",
  //   "Phone",
  //   "Topic",
  //   "Subject",
  //   "Message",
  // ];
  // requiredFields.forEach((field) => {
  //   if (!values[field]) {
  //     errors[field] = `${field.split("_").join(" ")} is required`;
  //   }
  // });

  if (!values["First_name"]) {
    errors.First_name = validateMessages.requiredFirstName;
  }
  if (!values["Last_name"]) {
    errors.Last_name = validateMessages.requiredLastName;
  }
  if (!values["Email"]) {
    errors.Email = validateMessages.requiredEmail;
  }
  if (!values["Phone"]) {
    errors.Phone = validateMessages.requiredPhone;
  }
  if (!values["Topic"]) {
    errors.Topic = validateMessages.requiredTopic;
  }
  if (!values["Subject"]) {
    errors.Subject = validateMessages.requiredSubject;
  }
  if (!values["Message"]) {
    errors.Message = validateMessages.requiredMessage;
  }

  // if (
  //   values.Email &&
  //   !/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}(?:\.[a-zA-Z]{2,})$/i.test(
  //     values.Email
  //   )
  // ) {
  //   errors.Email = validateMessages.invalidEmail;
  // }
  return errors;
};

//initialValues is null
const mapStateToProps = (state) => ({
  initialValues: {
    First_name: "",
    Last_name: "",
    Email: "",
    Phone: "",
    Subject: "",
    Sector: "",
    Topic: "",
    Message: "",
  },
});

const ContactformRedux = reduxForm({
  form: "Contactform",
  validate,
  enableReinitialize: true,
})(Investors);

export default connect(mapStateToProps)(ContactformRedux);
