import React from "react";
import axios from "axios";
import { useEffect, useState, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import translations from "../../translations/translation";
import Icon from "../../assets/icons/icons";
import PatternOne from "../../assets/icons/pattern2.svg";
import "./style.css";
import Lottie from "lottie-react";
import Loader from "../../assets/lottie/85060-slow-fading-circle-loader.json";
import CustomDropdown from "../../Component/CustomDropdown";
import BridgeStyle from "../../assets/icons/bridge-style.svg";

import { theme } from "../../theme";

const News = () => {
  const [data, setData] = React.useState();
  const { i18n } = useTranslation();
  const navigate = useNavigate();
  // const [selectedOption, setSelectedOption] = useState('mostRecent')
  const [searchQuery, setSearchQuery] = useState("");
  const [topArticale, setTopArticale] = React.useState();
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const [isLoading, setIsLoading] = React.useState(false);
  const [error, setError] = React.useState(false);

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    setIsLoading(true);

    axios
      .get(`${process.env.REACT_APP_BACKEND}/api/articles?populate=*`)
      .then((response) => {
        if (response.data.data.length === 0) {
          setIsLoading(true);
        } else {
          setIsLoading(false);
        }
        //This to set The top Artical based on two conction : flag and last Updated
        const sortedData = response.data.data.sort((a, b) => {
          return (
            new Date(b.attributes.updatedAt) - new Date(a.attributes.updatedAt)
          );
        });

        const topArticle = sortedData.find(
          (article) => article.attributes.top_article === true
        );

        if (topArticle) {
          setTopArticale(topArticle);
        }

        const filteredData = sortedData?.filter(
          (article) => article?.attributes?.top_article !== true
        );

        setData(filteredData);
      })
      .catch((error) => {
        setError(true);
        console.log(error);
      });
  };
  // This to open Details of article
  const onClick = (article) => {
    setIsLoading(true);

    axios
      .get(
        `${process.env.REACT_APP_BACKEND}/api/articles/${article.id}?populate=*`
      )
      .then((response) => {
        setIsLoading(false); 
        navigate(`/news/${article.id}`, {
          state: { response:response.data.data, article: article },
        });
      })
      .catch((error) => {
        // setIsLoading(true)
        setError(true);
        console.log(error);
      });
  };
  //DropDown for articale
  const handleOptionChange = (value) => {
    console.log("sdfsdfsdf");
    setIsLoading(true);

    console.log("Sdfsdfsdf", value);
    if (value === "mostRecent") {
      axios
        .get(
          `${process.env.REACT_APP_BACKEND}/api/articles?populate=*&sort=date:asc`
        )
        .then((response) => {
          setIsLoading(false);
          setData(response.data.data);
        })
        .catch((error) => {
          // setIsLoading(true)
          setError(true);
          console.log(error);
        });
    } else {
      axios
        .get(
          `${process.env.REACT_APP_BACKEND}/api/articles?populate=*&sort=title:${value}`
        )
        .then((response) => {
          setIsLoading(false);
          setData(response.data.data);
        })
        .catch((error) => {
          // setIsLoading(true)
          setError(true);
          console.log(error);
        });
    }

    // setSelectedOption(value)
  };
  //Search for articale
  const handleSearchChange = useCallback((event) => {
    const value = event.target.value;
    setSearchQuery(value);

    axios
      .get(
        `${process.env.REACT_APP_BACKEND}/api/articles?populate=*&filters[title][$contains]=${value}`
      )
      .then((response) => {
        setData(response.data.data);
        setIsLoading(false);
      })
      .catch((error) => {
        setError(true);
        console.log(error);
      });
  }, []);

  //This component to show recent article
  const SingleContainer = ({ article, onClick }) => {
    return (
      <div className="single-container">
        <img
          src={
            process.env.REACT_APP_BACKEND +
            article?.attributes?.image?.data?.attributes?.url
          }
          style={{
            width: "100%",
            height: "50%",
            objectFit: "cover",
            borderRadius: "2px",
            transform: "scale(1)",
            transition: "transform 0.3s ease-in-out",
          }}
          alt="this to sohw the news for the article"
        />

        <div className="details-container">
          <h4
            style={{
              color: "#5A5454",
              padding: "5px 0px",
            }}
          >
            {i18n.language === "en"
              ? article.attributes.title
              : article.attributes.title_ar}
          </h4>
          <h6
            style={{
              color: "#AEAEAE",
              fontSize: "12px",
              marginTop: window.innerWidth <= 600 ? "0" : "-10px",
              marginBottom: window.innerWidth <= 600 ? "0" : "-10px",

              fontWeight: "normal",
            }}
          >
            {new Date(article.attributes.date).toLocaleDateString("en-US", {
              day: "numeric",
              month: "long",
              year: "numeric",
            })}
          </h6>
          <div className="read-more" onClick={() => onClick(article)}>
            <h5
              style={{
                color: theme.primary,
                fontSize: "16px",
                fontStyle: "italic",
                fontWeight: 500,
                letterSpacing: "-0.288px",
                textTransform: "capitalize",

                margin: "0",
              }}
            >
              {translations[i18n.language].read_more}
            </h5>
          </div>
        </div>
      </div>
    );
  };
  // const handleMouseEnter = (index) => {
  //   setHoveredIndex(index);
  // };

  // const handleMouseLeave = () => {
  //   setHoveredIndex(null);
  // };

  return (
    <div className="news-container">
      {error ? (
        <div className="error-container">
          <Icon
            name="error"
            size={100}
            fill={theme.primary}
            style={{ marginRight: "20px" }}
          />
          <h3 className="error-title">{translations[i18n.language].wrong}</h3>
        </div>
      ) : isLoading ? (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            justifyContent: "center",
            width: "100%",
            height: "100vh",
          }}
        >
          <Lottie animationData={Loader} style={{ width: 120, height: 200 }} />
        </div>
      ) : (
        <>
          <h2 style={{ color: "#4E4E4E" }}>
            {translations[i18n.language].news_insight}
          </h2>
          <div style={{ display: "flex", flexDirection: "column" }}>
            {/* Show top article separately */}
            {topArticale && (
              <div className="top-container">
                <div
                  style={{
                    display: "flex",
                    flex: 2,
                    alignItems: "flex-start", // Align items at the top
                    justifyContent:
                      window.innerWidth <= 768 ? "center" : "none",
                    position: "relative", // Create a positioning context for absolute positioning
                  }}
                >
                  <img
                    src={
                      process.env.REACT_APP_BACKEND +
                      topArticale?.attributes?.image?.data?.attributes?.url
                    }
                    style={{
                      width: window.innerWidth <= 768 ? "100%" : "94%",
                      aspectRatio: "9 / 6",

                      // borderRadius: '2px'
                    }}
                    alt="this to show the for the newss"
                  />
                  {/* <img
                    src={BridgeStyle}
                    // className='bridge-style'
                    style={{
                      width: window.innerWidth <= 768 ? "100%" : "94%",
                      position: "absolute", // Position absolutely within the container
                      bottom: 0, // Stick to the bottom of the container
                      left: 0, // Stick to the left of the container
                      right: 0,
                    }}
                  /> */}
                </div>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    flex: 2,
                    justifyContent:
                      window.innerWidth <= 768 ? "center" : "space-between",
                  }}
                >
                  <div>
                    <h2
                      style={{
                        color: theme.black,
                        marginTop: "8px",
                        fontStyle: "italic",
                      }}
                    >
                      {i18n.language === "en"
                        ? topArticale.attributes.title
                        : topArticale.attributes.title_ar}
                    </h2>
                    <h6
                      style={{
                        color: "#AEAEAE",
                        marginTop: "0",
                        fontWeight: "300",
                        lineHeight: "109.5%",
                        letterSpacing: "-0.338px",
                        textTransform: "capitalize",
                      }}
                    >
                      {new Date(topArticale.attributes.date).toLocaleDateString(
                        "en-US",
                        {
                          day: "numeric",
                          month: "long",
                          year: "numeric",
                        }
                      )}
                    </h6>
                  </div>

                  <div
                    style={{
                      color: "#7E8389",
                      height: "120px",
                      display: "contents",
                      overflow: "hidden",
                    }}
                  >
                    {i18n.language === "en"
                      ? topArticale?.attributes.body
                      : topArticale?.attributes.body_ar

                          .split(".")
                          .slice(0, 3)
                          .map((paragraph, index) => (
                            <article
                              style={{
                                fontSize: "16px",
                                fontStyle: "normal",
                                fontWeight: "400",
                                lineHeight: "179.023%",
                                letterSpacing: " 0.5px",
                                textTransform: "capitalize",
                              }}
                              key={index}
                            >
                              {paragraph}.
                            </article>
                          ))}
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      cursor: "pointer",
                      marginTop: "10px",
                      justifyContent: "flex-end",
                    }}
                    onClick={() => onClick(topArticale)}
                  >
                    <h5
                      style={{
                        fontSize: "16px",
                        fontWeight: "500",
                        color: theme.primary,
                        margin: 15,
                        lineHeight: "normal",
                        textTransform: "capitalize",
                        fontStyle: "italic",
                      }}
                    >
                      {translations[i18n.language].read_more}
                    </h5>
                  </div>
                </div>
              </div>
            )}

            {/* Show other articles for the rest */}
            <div className="search-container">
              <h2 style={{ color: "#4E4E4E" }}>
                {translations[i18n.language].recent_art}
              </h2>

              <div className="search-inputs">
                {/* <div
                  style={{
                    position: "relative",

                    marginRight: i18n.language === "en" ? "10px" : "5px",
                  }}
                  className="search-input-field"
                >
                  <input
                    type="text"
                    value={searchQuery}
                    onChange={handleSearchChange}
                    placeholder={translations[i18n.language].search}
                    className="search-field"
                  />
                  <Icon
                    name="search"
                    fill={theme.primary}
                    size={15}
                    style={{
                      position: "absolute",
                      right: i18n.language === "en" ? "10px" : null,
                      left: i18n.language === "ar" ? "10px" : null,
                      bottom: window.innerWidth <= 768 ? "30px" : "16px",
                    }}
                  />
                </div> */}
                {/* <CustomDropdown
                  options={[
                    {
                      label: translations[i18n.language].mostRecent,
                      value: "mostRecent",
                    },
                    {
                      label: translations[i18n.language].ascendingOrder,
                      value: "asc",
                    },
                    {
                      label: translations[i18n.language].descendingOrder,
                      value: "desc",
                    },
                  ]}
                  defaultOption={translations[i18n.language].select}
                  arrowIcon="arrowDown"
                  arrowColor={theme.primary}
                  icon={{
                    name: "arrowDown",
                    fill: theme.primary,
                    size: 10,
                    marginArrow: "8px",
                  }}
                  className="select-wrapper"
                  onChange={handleOptionChange}
                /> */}
              </div>
            </div>
            <div className="recent-container">
              {data
                ?.filter(
                  (article) => article?.attributes?.top_article === false
                )
                .map((article, index) => (
                  <SingleContainer
                    key={article.id}
                    article={article}
                    onClick={onClick}
                  />
                ))}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default News;
