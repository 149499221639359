import React, { useState } from 'react';
import i18n from './i18n';


const LanguageSwitch=()=> {
  const [language, setLanguage] = useState('en');

  const handleClick = () => {
    const newLanguage = language === 'en' ? 'ar' : 'en';
    setLanguage(newLanguage);
    i18n.changeLanguage(newLanguage);
  };

  return (
    <div onClick={handleClick} style={{cursor:'pointer'}}>
      {language === 'en' ? 'AR' : 'EN'}
    </div>
  );
}

export default LanguageSwitch;