import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import axios from "axios";
import Icon from "../../assets/icons/icons";
import translations from "../../translations/translation";
import { useNavigate } from "react-router-dom";
import { theme } from "../../theme.js";
const PageNotFound = () => {
  const { i18n } = useTranslation();
  const [data, setData] = useState(); // State to hold data fetched from API
  const navigate = useNavigate();

  useEffect(() => {
    getData(); // Fetch data when the component mounts
  }, []);

  const getData = () => {
    axios
      .get(`${process.env.REACT_APP_BACKEND}/api/dashboards?populate=*`)
      .then((response) => {
        setData(response.data.data); // Store fetched data in the state
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  const goBack = () => {
    navigate("/");
  };

  return (
    <Wrapper>
      {/* Display hero section if data is available */}
      {data && data.length > 0 && (
        <Hero
          image={data[0].attributes.image.data.attributes.url}
          language={i18n.language}
        >
          {/* Content within the hero section */}
          <Content language={i18n.language}>
            <Icon
              name="error"
              size={100}
              fill={theme.primary}
              style={{ marginBottom: "0" }}
            />
            <h6
              style={{
                color: theme.white,
                marginTop: "10px",
                marginBottom: "10px",
                fontWeight: "700",
                fontSize: "16px",
              }}
            >
              404 Error
            </h6>
            <h3
              style={{
                color: theme.primary,
                marginBottom: "0",
                marginTop: "10px",
                fontWeight: "400",
                fontSize: "40px",
              }}
            >
              Page Not Found
            </h3>
            <h6
              style={{
                color: theme.white,
                marginTop: "10px",
                fontWeight: "400",
                fontSize: "20px",
              }}
            >
              {translations[i18n.language].sorry}
            </h6>
            <button
              onClick={goBack}
              className="form-submit-button"
              style={{
                width: "20%",
                backgroundColor: theme.primary,
                color: theme.white,
              }}
            >
              Go To Home page
            </button>
          </Content>
        </Hero>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  @media (max-width: 1000px) {
    /* Add responsive styles here */
  }
`;

const Hero = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  padding: 20px;
  background-image: url("${({ image }) =>
    process.env.REACT_APP_BACKEND + "/" + image.replace("/", "")}");
  background-size: cover;
  position: relative;
  height: 100vh; /* Set the height to 100% of the viewport height */

  @media (max-width: 1000px) {
    /* Add mobile styles here */
  }
`;

const Content = styled.div`
  color: ${theme.white};
  width: 60%;
  padding: 0 60px; /* Add left and right padding of 60px */
  margin-top: 20%;

  @media (max-width: 1000px) {
    margin-top: 40%;
    /* Add responsive styles here */
  }
}`;

export default PageNotFound;
