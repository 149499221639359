import React from "react";
import axios from "axios";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import translations from "../../translations/translation";
import Lottie from "lottie-react";
import Loader from "../../assets/lottie/85060-slow-fading-circle-loader.json";
import Icon from "../../assets/icons/icons";
import LineBridge from "../../assets/icons/line-bridge.svg";
import BridgeStyle from "../../assets/icons/image-bridge.svg";
import "./style.css";
import { theme } from "../../theme";

const BoradMemeber = () => {
  const { t, i18n } = useTranslation();
  const [data, setData] = React.useState();
  const [isLoading, setIsLoading] = React.useState(false);
  const [error, setError] = React.useState(false);

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    setIsLoading(true);
    axios
      .get(`${process.env.REACT_APP_BACKEND}/api/board-memebers?populate=*`)
      .then((response) => {
        if (response.data.data.length > 0) {
          setIsLoading(false);
        }
        setData(response.data.data);
      })
      .catch((error) => {
        setError(true);
        setIsLoading(false);

        console.log(error);
      });
  };
  // Method to fromat the rich text returend from the API
  const getFormattedText = (text) => {
    const formattedText = text?.replace(/\n/g, "<br />");
    return { __html: formattedText };
  };
  return (
    <>
      {error ? (
        <div className="error-container">
          <Icon
            name="error"
            size={100}
            fill={theme.primary}
            style={{ marginRight: "20px" }}
          />
          <h3 className="error-title">{translations[i18n.language].wrong}</h3>
        </div>
      ) : isLoading ? (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            justifyContent: "center",
            width: "100%",
            height: "100vh",
          }}
        >
          <Lottie animationData={Loader} style={{ width: 120, height: 200 }} />
          {/* <h3 style={{color:'#000',fontWeight:'700',opacity:'0.7'}}>Something went wrong...</h3> */}
        </div>
      ) : (
        <div className="board-page">
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              padding: "10px",
              fontStyle: "italic",
            }}
          >
            <h1
              style={{
                fontSize: "22px",
                fontWeight: "normal",
                marginBottom: "20px",
              }}
            >
              {translations[i18n.language].board_members}
            </h1>

            {data && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  padding: "20px",
                  borderRadius: "3px",
                  border: "1px solid #F4F4F4",
                  background: "var(--White, #FFF)",
                  boxShadow: "4px 5px 12px 0px rgba(237, 237, 237, 0.15)",
                }}
              >
                {data
                  ?.filter(
                    (member) => member.attributes.position.trim() === "Chairman"
                  )
                  .map((member) => (
                    <div className="chairman-continer">
                      <div
                        className="team-member"
                        key={member.attributes.name}
                        style={{
                          // display: 'flex',
                          // flexDirection: 'column',
                          // alignItems: 'center',
                          marginLeft: i18n.language === "ar" ? "10px" : "",
                          marginRight: i18n.language === "ar" ? "" : "10px",
                          marginTop: "8px",
                          width: "300px",
                          flex: "3",
                          padding: "10px",
                          // height: '396px'
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "flex-start",
                            alignItems: "center",
                            overflow: "hidden",
                            borderRadius: "3px",
                            position: "relative",
                            width: "196px",
                          }}
                        >
                          {/* Background Image */}
                          <div
                            style={{
                              position: "absolute",
                              top: 0,
                              left: 0,
                              right: 0,
                              bottom: "-2%",
                              marginTop: "20px",
                            }}
                          ></div>

                          {/* Image */}
                          <img
                            src={
                              process.env.REACT_APP_BACKEND +
                              member.attributes.image.data?.attributes?.url
                            }
                            style={{
                              width: "100%",
                              height: "239px",
                            }}
                          />
                        </div>

                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            marginTop: "10px",
                            // justifyContent: 'space-evenly',
                          }}
                        >
                          <h2
                            style={{
                              fontSize: "16px",
                              fontWeight: "500",
                              color: theme.secondary,
                              marginBottom: "3px",
                              lineHeight: "100%",
                              fontStyle: "italic",
                              padding: "10px 0px",
                            }}
                          >
                            {i18n.language === "en"
                              ? member.attributes.name
                              : member.attributes.name_ar}
                          </h2>
                          <h6
                            style={{
                              fontSize: "12px",
                              marginTop: "0",
                              color: theme.grey,
                              fontWeight: "400",
                              lineHeight: "normal",
                            }}
                          >
                            {i18n.language === "en"
                              ? member.attributes.position
                              : member.attributes.position_ar}
                          </h6>
                        </div>
                      </div>
                      <div className="board-intro">
                        <h6
                          style={{
                            fontWeight: "lighter",
                            fontSize: "20px",
                            wordSpacing: "5px",
                            color: theme.grey,
                          }}
                          dangerouslySetInnerHTML={getFormattedText(
                            t("boardMembers.title")
                          )}
                        ></h6>
                      </div>
                    </div>
                  ))}
                <div className="board-container">
                  {/* Show all team members */}
                  {data
                    ?.filter(
                      (member) =>
                        member.attributes.position.trim() !== "Chairman"
                    )

                    .map((member, index, array) => (
                      <div className="team-member ">
                        {/* Your existing content for each team member */}
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "flex-start",
                            alignItems: "center",
                            overflow: "hidden",
                            borderRadius: "3px",
                            position: "relative",
                            width: "196px",
                          }}
                        >
                          {/* Background Image */}
                          <div
                            style={{
                              position: "absolute",
                              top: 0,
                              left: 0,
                              right: 0,
                              bottom: "-2%",
                              marginTop: "20px",
                            }}
                          ></div>

                          {/* Image */}
                          <img
                            src={
                              process.env.REACT_APP_BACKEND +
                              "/" +
                              member.attributes.image.data?.attributes?.url?.replace(
                                "/",
                                ""
                              )
                            }
                            style={{
                              width: "100%",
                              height: "239px",
                            }}
                          />
                        </div>

                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            marginTop: "5px",
                            height: "auto",
                            width: "100%",
                          }}
                        >
                          <h2
                            style={{
                              fontSize: "16px",
                              fontWeight: "500",
                              color: theme.secondary,
                              marginBottom: "3px",
                              lineHeight: "100%",
                              fontStyle: "italic",
                              padding: "10px 0px",
                            }}
                          >
                            {i18n.language === "en"
                              ? member.attributes.name
                              : member.attributes.name_ar}
                          </h2>
                          <h6
                            style={{
                              fontSize: "12px",
                              marginTop: "0",
                              color: theme.grey,
                              fontWeight: "400",
                              lineHeight: "normal",
                            }}
                          >
                            {i18n.language === "en"
                              ? member.attributes.position
                              : member.attributes.position_ar}
                          </h6>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default BoradMemeber;
