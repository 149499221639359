import React from "react";
import { useEffect, useState } from "react";
import axios from "axios";

import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import translations from "../../translations/translation";
import Icon from "../../assets/icons/icons";
import shadowIcon from "../../assets/icons/shadowss.svg";
import PatternOne from "../../assets/icons/pattern2.svg";
import "./style.css";
import { theme } from "../../theme";
import Lottie from "lottie-react";
import Loader from "../../assets/lottie/85060-slow-fading-circle-loader.json";
import BridgeStyle from "../../assets/icons/bridge-style.svg";

const Article = () => {
  const { i18n } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();

  const { response, article } = {
    response: location?.state?.response,
    article: location?.state?.article,
  };
  const [tweetText, setTweetText] = useState("");
  const [selectedOption, setSelectedOption] = useState("mostRecent");
  const [data, setData] = useState();
  const [isLoading, setIsLoading] = React.useState(false);
  const [error, setError] = React.useState(false);
  // const [hoveredIndex, setHoveredIndex] = useState(null);

  // Fetch and set related articles data
  useEffect(() => {
    if (!location?.state) {
      navigate("/error");
    }
    setData(response?.attributes?.articles?.data);
  }, [data, response]);

  const shareOnLinkedIn = (data) => {
    console.log("shareOnLinkedIn");
    const postUrl = "https://www.example.com/my-post";
    const title = "sdfsdf";
    const summary = "sdfsdf";
    const source = "My Website";

    const url = `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(
      postUrl
    )}&title=${encodeURIComponent(title)}&summary=${encodeURIComponent(
      summary
    )}&source=${encodeURIComponent(source)}`;

    window.open(url, "_blank");
  };

  // const handleTweetTextChange = (event) => {
  //   setTweetText("sdkfjsdkfsdkjfhsdkjfskdfjsdkf");
  // };

  const handleTweetTextChange = async (text) => {
    console.log("handleTweetTextChange");
    const url = "https://api.twitter.com/1.1/statuses/update.json";
    const headers = new Headers({
      Authorization: `Bearer AAAAAAAAAAAAAAAAAAAAAGMHpAEAAAAAtcyIcmZXz1Mjz9Tv5XI7HJGV2Tw%3DlHZ1BcfNYTMhiLRtxrddmX4gJXYLOFKiXk3iTNtrqpebstsZfD`,
      "Content-Type": "application/x-www-form-urlencoded",
    });
    const options = {
      method: "POST",
      headers: headers,
      body: new URLSearchParams({
        status: text,
      }),
    };
    try {
      const response = await fetch(url, options);
      if (response.ok) {
        console.log("Tweet posted successfully!");
      } else {
        console.error("Failed to post tweet:", response.statusText);
      }
    } catch (error) {
      console.error("Failed to post tweet:", error);
    }
  };
  // const handleMouseEnter = (index) => {
  //   setHoveredIndex(index);
  // };

  // const handleMouseLeave = () => {
  //   setHoveredIndex(null);
  // }; // Open an article by fetching its details from the API and scroll to the top
  const openArticle = (article) => {
    setIsLoading(true);

    axios
      .get(
        `${process.env.REACT_APP_BACKEND}/api/articles/${article.id}?populate=*`
      )
      .then((response) => {
        setIsLoading(false);

        navigate(`/news/${article?.attributes.title}`, {
          state: { response: response.data.data, article: article },
        });

        // Scroll to the top of the page
        window.scrollTo({ top: 0, behavior: "smooth" });
      })
      .catch((error) => {
        // setIsLoading(true)
        setError(true);
        console.log(error);
      });
  };

  console.log("======", data);

  return (
    <div className="news-container">
      {error ? (
        <div className="error-container">
          <Icon
            name="error"
            size={100}
            fill={theme.primary}
            style={{ marginRight: "20px" }}
          />
          <h3 className="error-title">{translations[i18n.language].wrong}</h3>
        </div>
      ) : isLoading && article ? (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            justifyContent: "center",
            width: "100%",
            height: "100vh",
          }}
        >
          <Lottie animationData={Loader} style={{ width: 120, height: 200 }} />
        </div>
      ) : (
        <div className="article-container">
          <h2
            style={{
              color: "#4E4E4E",
              marginBottom: "50px",
              fontStyle: "italic",
            }}
          >
            {translations[i18n.language].news_insight}
          </h2>

          <div style={{ display: "flex", flexDirection: "column" }}>
            <div style={{ position: "relative" }}>
              <div className="top2-container">
                <div
                  style={{
                    justifyContent: "center",
                    alignItems: "center",
                    flex: 3,
                    marginTop: "20px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "flex-start", // Align items at the top
                      justifyContent: "center",
                      position: "relative", // Create a positioning context for absolute positioning
                    }}
                  >
                    <img
                      src={
                        process.env.REACT_APP_BACKEND +
                        article?.attributes?.image?.data?.attributes?.url
                      }
                      style={{
                        width: window.innerWidth <= 768 ? "100%" : "80%",
                        aspectRatio: "9 / 6",
                        // borderRadius: '2px'
                      }}
                      alt="this to show the for the news"
                    />
                    {/* <img
                      src={BridgeStyle}
                      // className='bridge-style'
                      style={{
                        width: window.innerWidth <= 768 ? "100%" : "94%",
                        position: "absolute", // Position absolutely within the container
                        bottom: 0, // Stick to the bottom of the container
                        left: 0, // Stick to the left of the container
                      }}
                    /> */}
                  </div>
                  <h2 style={{ margin: "20px 0px" }}>
                    {i18n.language === "en"
                      ? article?.attributes?.title
                      : article?.attributes?.title_ar}
                  </h2>
                  <h6
                    style={{
                      color: "#AEAEAE",
                      fontSize: "10px",

                      fontWeight: "normal",
                    }}
                  >
                    {new Date(article?.attributes.date).toLocaleDateString(
                      "en-US",
                      {
                        day: "numeric",
                        month: "long",
                        year: "numeric",
                      }
                    )}
                  </h6>
                  <p
                    style={{
                      overflow: "hidden",
                      fontSize: "16px",
                      textAlign: "justify",
                      lineHeight: 1.5,
                      color: theme.grey,
                    }}
                  >
                    {i18n.language === "en"
                      ? article?.attributes.body
                      : article?.attributes.body_ar}
                    .
                  </p>
                </div>
                {/* <div className="share-container">
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      height: "200px",
                      position: "sticky",
                      // padding: '15px',
                      top: "73px",
                    }}
                  >
                    <div
                      style={{
                        content: "''",
                        position: "absolute",
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        // backgroundColor: 'rgba(138, 188, 75, 0.09)',
                        // opacity: 0.5
                      }}
                    />
                    <div
                      style={{
                        padding: window.innerWidth <= 768 ? "4px" : "20px",
                      }}
                    >
                      <h5
                        style={{
                          color: theme.grey,
                          fontSize: "20px",
                          fontWeight: "500",
                          textTransform: "capitalize",
                          margin: "0",
                        }}
                      >
                        {" "}
                        {translations[i18n.language].published}
                      </h5>
                      <h5
                        style={{
                          margin: "0",
                          fontSize: "20px",
                          fontWeight: "500",
                          textTransform: "capitalize",
                        }}
                      >
                        {" "}
                        {new Date(article?.attributes.date).toLocaleDateString(
                          "en-US",
                          {
                            day: "numeric",
                            month: "long",
                            year: "numeric",
                          }
                        )}
                      </h5>

                      <div
                        style={{
                          borderTop: `0.04px solid ${theme.grey}`,
                          margin: "30px 13px",
                          width: "80%",
                          opacity: "0.2",
                        }}
                      ></div>

                      {/* <h5
                        style={{
                          color: theme.grey,
                          fontSize: "20px",
                          fontWeight: "500",
                          textTransform: "capitalize",
                          margin: "0",
                        }}
                      >
                        {translations[i18n.language].share}
                      </h5> */}
                {/* 
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <Icon
                          name="twitter"
                          fill={theme.primary}
                          size={22.494}
                          style={{ marginRight: "5px" }}
                          onClick={() =>
                            handleTweetTextChange(article?.attributes.body)
                          }
                        />
                        lmkkk
                        <Icon
                          name="linkedin"
                          fill={theme.primary}
                          size={21}
                          onClick={() => shareOnLinkedIn()}
                        />
                      </div> */}
                {/* </div>
                  </div>
                </div> */}
              </div>
            </div>
            {/* <div style={{ paddingTop: "80px" }}>
              <div></div>
              {data?.length !== 0 && (
                <h3 style={{ fontStyle: "italic" }}>
                  {" "}
                  {translations[i18n.language].related_art}
                </h3>
              )}
              <div className="related-continer">
                {console.log('rtrtr')}
                {data?.slice(-2).map((article, index) => (
                  <div
                    style={{
                      flex: 2,
                      maxWidth: window.innerWidth <= 768 ? "none" : "30%",
                      marginRight: "30px",
                      borderRadius: "3px",
                      background: "var(--White, #FFF)",
                      boxShadow: "0px 5px 10px 0px rgba(0, 0, 0, 0.15)",
                    }}
                  >
                    <img
                      src={
                        process.env.REACT_APP_BACKEND +
                        article?.attributes?.image?.data?.attributes.url
                      }
                      style={{
                        width: "100%",
                        height: "auto",
                        objectFit: "cover",
                        borderRadius: "2px",
                        transform: "scale(1)",
                        transition: "transform 0.3s ease-in-out",
                      }}
                      alt={"this to show the for the article"}
                    /> 

                    <div className="details-container">
                      <h4
                        style={{
                          color: "#5A5454",
                          marginTop: "8px",
                          fontSize: "16px",
                        }}
                      >
                        {i18n.language === "en"
                          ? article?.attributes?.title
                          : article?.attributes?.title_ar}
                      </h4>
                      <h6
                        style={{
                          color: "#AEAEAE",
                          fontSize: "12px",
                          margin: "0",
                        }}
                      >
                        {new Date(article?.attributes?.date).toLocaleDateString(
                          "en-US",
                          {
                            day: "numeric",
                            month: "long",
                            year: "numeric",
                          }
                        )}
                      </h6>
                      <div
                        className="read-more"
                        // onClick={() => openArticle(article)}
                      >
                        <h6
                          style={{
                            fontSize: "16px",
                            fontStyle: "italic",
                            fontWeight: "normal",
                            color: theme.primary,
                            margin: 0,
                          }}
                        >
                          {translations[i18n.language].read_more}
                        </h6>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>  */}
          </div>
        </div>
      )}
    </div>
  );
};
export default Article;
