import React, { useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import { createGlobalStyle } from "styled-components";
import { useTranslation } from "react-i18next";
import Headers from "./Component/headers";
import Footer from "./Component/footers";
import Dashboard from "./Containner/dashboard";
import News from "./Containner/news";
import Investors from "./Containner/investors";
import BoardMembers from "./Containner/board";
import Article from "./Containner/news/article";
import PageNotFound from "./Component/error";
import { loadTranslations } from "./i18n";
import { theme } from "./theme";
import { useLocation } from "react-router-dom";

const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    padding: 0;
  }

  #root {
    min-height: 100vh;
    --primary: ${theme.primary};
    --secondary: ${theme.secondary};
    --grey:${theme.grey};
    --black:${theme.black};
    --white:${theme.white};
  }
  
`;

function App() {
  const { i18n } = useTranslation();
  const location = useLocation();

  useEffect(() => {
    i18n.init({
      lng: "en",
      fallbackLng: "en",
      keySeparator: false,
      interpolation: {
        escapeValue: false,
      },
    });
    loadTranslations();
  }, [i18n]);
  useEffect(() => {
    async function fetchTranslations() {
      try {
        await loadTranslations();
      } catch (error) {
        console.error("Error fetching translations:", error.message);
      } finally {
      }
    }

    fetchTranslations();
  }, []);

  return (
    <>
      <GlobalStyle />
      <Headers />

      <div
        id="root"
        style={{
          marginTop: location.pathname !== "/" ? "10%" : "0px",
        }}
      >
        {/* {hasError && <div>Error loading translations. Please try again.</div>} */}
        <Routes style={{ flex: 1, padding: "20px" }}>
          <Route path="/" element={<Dashboard />} />
          <Route path="/news" element={<News />} />
          <Route path="/board-members" element={<BoardMembers />} />
          <Route path="/contact-us" element={<Investors />} />
          <Route path="/news/:id" element={<Article />} />
          <Route path="*" element={<PageNotFound />} />
          <Route path="/error" element={<PageNotFound />} />
        </Routes>
      </div>
      <Footer />
    </>
  );
}

export default App;
