import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import store from './store';
import App from './App';
import { useTranslation } from 'react-i18next';
import { BrowserRouter } from 'react-router-dom';
import './index.css'; 
import { theme } from './theme';
import '@coreui/coreui/dist/css/coreui.min.css'
import "bootstrap/dist/css/bootstrap.min.css";

const rootElement = document.getElementById('root');
if (rootElement) {
  createRoot(rootElement).render(
    <BrowserRouter>
      <Provider store={store} theme={theme} >
        <AppWithI18n />
      </Provider>
    </BrowserRouter>
  );
}

function AppWithI18n() {
  const { i18n } = useTranslation();

  return (
    <div dir={i18n.language === 'ar' ? 'rtl' : 'ltr'}>
      <App />
    </div>
  );
}