// theme.js

export const theme = {
  primary: "#8ABC4B",
  secondary: "#0D5D3F",
  grey: "#7E8389",
  black: "#232121",
  white: "#FFFFFF",
  green: "#0D5D3F",
};
