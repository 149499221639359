import React from "react";
import { useState, useEffect } from "react";
import axios from "axios";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import image from "../../assets/image/Frame.png";
import shadowIcon from "../../assets/icons/abouts.svg";
import translations from "../../translations/translation";
import Icon from "../../assets/icons/icons";
import Map from "../../assets/icons/map";
import ReactPlayer from "react-player";
import Sectors from "../../assets/image/sectors.png";
import Lottie from "lottie-react";
import Companies from "../../assets/icons/Map.svg";
import Loader from "../../assets/lottie/85060-slow-fading-circle-loader.json";
import BridgeStyle from "../../assets/icons/bridge-style.svg";
import BridgeStyleMobile from "../../assets/icons/bridge-style-mobile.svg";

import SectorBridgeStyle from "../../assets/icons/sectors.svg";
import "./style.css";
import { theme } from "../../theme";
const Dashboard = () => {
  const { t } = useTranslation();
  const { i18n } = useTranslation();
  const [data, setData] = useState([
    { attributes: { image: { data: { attributes: { url: "" } } } } },
  ]);
  // const [expandedPortfolio, setExpandedPortfolio] = useState(false)
  const [portfolios, setPortfolios] = useState();
  const [companies, setCompanies] = useState();
  const [isVideoModalOpen, setIsVideoModalOpen] = useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [error, setError] = React.useState(false);

  useEffect(() => {
    getData();
    getPortfolios();
    getCompanies();
  }, []);

  const getData = () => {
    setIsLoading(true);
    axios
      .get(`${process.env.REACT_APP_BACKEND}/api/dashboards?populate=*`)
      .then((response) => {
        if (response.data.data.length === 0) {
          setIsLoading(true);
        } else {
          setIsLoading(false);
        }
        setData(response.data.data);
      })
      .catch((error) => {
        setIsLoading(false);
        setError(true);
        console.log(error);
      });
  };

  const getPortfolios = () => {
    axios
      .get(`${process.env.REACT_APP_BACKEND}/api/portfolios?populate=*`)
      .then((response) => {
        setPortfolios(response.data.data);
      })
      .catch((error) => {
        setError(true);

        console.log(error);
      });
  };

  const getCompanies = () => {
    axios
      .get(`${process.env.REACT_APP_BACKEND}/api/partners?populate=*`)
      .then((response) => {
        setCompanies(response.data.data);
      })
      .catch((error) => {
        setError(true);
        console.log(error);
      });
  };

  const openVideoModal = () => {
    setIsVideoModalOpen(true);
  };

  const closeVideoModal = () => {
    setIsVideoModalOpen(false);
  };
  // Format text to be displayed as HTML
  const getFormattedText = (text) => {
    // Remove <span> tags inside <p> tags
    const sanitizedText = text?.replace(
      /<p>.*?<span.*?>(.*?)<\/span>.*?<\/p>/gs,
      "$1"
    );

    const test = sanitizedText?.replace(/<pre>|<\/pre>|<code>|<\/code>/g, "");
    return test;
  };
  const getFormattedPargraph = (text) => {
    const formattedText = text?.replace(/\n/g, "<br />");
    return { __html: formattedText };
  };
  const handleClick = (link) => {
    window.open(link, "_blank");
  };
  return (
    <>
      {error ? (
        <div className="error-container">
          <Icon
            name="error"
            size={100}
            fill={theme.primary}
            style={{ marginRight: "20px" }}
          />
          <h3 className="error-title">{translations[i18n.language].wrong}</h3>
        </div>
      ) : isLoading ? (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            justifyContent: "center",
            width: "100%",
            height: "100vh",
          }}
        >
          <Lottie animationData={Loader} style={{ width: 120, height: 200 }} />
        </div>
      ) : (
        <Wrapper>
          <Hero
            image={data[0]?.attributes?.image?.data?.attributes?.url}
            language={i18n.language}
          >
            <Title language={i18n.language}>
              {" "}
              <div
                dangerouslySetInnerHTML={{
                  __html: getFormattedText(t("dashboard.title")),
                }}
              />
            </Title>

            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginBottom: "28%",
                backgroundColor: theme.primary,
                // padding: window.innerWidth <= 600 ? '0 10px' : '0 72px'
              }}
            >
              <Button onClick={openVideoModal} language={i18n.language}>
                <h5>{translations[i18n.language].playVideo}</h5>
                <Icon
                  name="play"
                  size={20}
                  style={{
                    transform: i18n.language === "en" ? "" : "rotate(180deg)",
                  }}
                  fill={theme.primary}
                />{" "}
              </Button>
            </div>
            {isVideoModalOpen && (
              <div className="modalx">
                <div className="modal-contentx">
                  <div
                    className={
                      i18n.language === "en"
                        ? "player-wrapper"
                        : "player-wrapper-ar"
                    }
                  >
                    <ReactPlayer
                      url={
                        process.env.REACT_APP_BACKEND +
                        "/" +
                        data[0]?.attributes?.video?.data?.attributes?.url.replace(
                          "/",
                          ""
                        )
                      }
                      playing={isVideoModalOpen}
                      controls
                      width="100%"
                      height="auto"
                      className="react-player"
                    />
                    <div
                      onClick={() => closeVideoModal()}
                      className={
                        i18n.language === "en"
                          ? "close-icon-en"
                          : "close-icon-ar"
                      }
                    >
                      <Icon name="closeIcon" fill="#84868a" size={20} />
                    </div>
                  </div>
                </div>
              </div>
            )}
          </Hero>

          <Content>
            <SectionOne language={i18n.language}>
              <About language={i18n.language}>
                <SectionTitle
                  dir={i18n.language === "ar" ? "rtl" : "ltr"}
                  style={{
                    color: theme.green,
                    marginTop: "0",
                    fontStyle: "italic",
                  }}
                >
                  {translations[i18n.language].about}
                </SectionTitle>
                <SectionContent
                  style={{
                    marginTop: "0",
                  }}
                  language={i18n.language}
                  dangerouslySetInnerHTML={getFormattedPargraph(
                    t("about.title")
                  )}
                ></SectionContent>
              </About>
            </SectionOne>
            <Section>
              <div className="crown-container">
                {/* Use CSS to make image-container the same size as Sections */}
                <div className="image-container">
                  <img
                    src={image}
                    className="image"
                    style={{ width: "200px" }}
                    alt="This is for crown"
                  />
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        flex: "1",
                        textAlign: "start",
                        marginTop: "20px",
                        // marginRight: window.innerWidth <= 768 ? "0" : "150px",
                      }}
                    >
                      <h6
                        className="values"
                        style={{ color: theme.black }}
                        dangerouslySetInnerHTML={{
                          __html: getFormattedText(t("person.name")),
                        }}
                      ></h6>

                      <h6
                        className="values"
                        style={{ color: theme.black }}
                        dangerouslySetInnerHTML={{
                          __html: getFormattedText(t("person.position")),
                        }}
                      ></h6>
                    </div>
                  </div>
                </div>
                <Sections>
                  <Icon
                    name="qoute"
                    size={window.innerWidth <= 768 ? 47.397 : 88}
                    fill={theme.grey}
                    className="icons"
                    style={{
                      opacity: ".4",
                      transform:
                        i18n.language === "ar"
                          ? "rotate(180deg)"
                          : "rotate(0deg)",
                    }}
                  />
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                    }}
                  >
                    <h1
                      dangerouslySetInnerHTML={{
                        __html: getFormattedText(t("person.word")),
                      }}
                      className="word"
                    ></h1>
                  </div>
                  <Icon
                    name="qoute"
                    size={window.innerWidth <= 768 ? 47.397 : 88}
                    fill={theme.grey}
                    style={{
                      opacity: ".4",

                      transform:
                        i18n.language === "ar"
                          ? "rotate(0deg)"
                          : "rotate(180deg)",
                      alignSelf: "end",
                    }}
                  />
                </Sections>
              </div>
            </Section>

            <Section style={{ backgroundColor: "white" }}>
              <SectionTitle>{translations[i18n.language].sectors}</SectionTitle>
              <div
                className="sectors-container"
                style={{
                  flexDirection:
                    i18n.language === "en" ? "none" : "row-reverse",
                  aspectratio: "9 / 6",
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "contain",
                  // padding: '20px',
                }}
              >
                <SectorsContainer>
                  {portfolios?.length === 0 ? (
                    <h4>No sectors available</h4>
                  ) : (
                    portfolios?.map((sector) => (
                      <SectorItem key={sector.id}>
                        {/* Place your sector data inside the image border */}
                        <img
                          src={
                            process.env.REACT_APP_BACKEND +
                            "/" +
                            sector?.attributes?.icon?.data?.attributes?.url?.replace(
                              "/",
                              ""
                            )
                          }
                          style={{ margin: "20px 0px" }}
                          alt="This used for Logo"
                        />
                        <h3 className="sectors-titles">
                          {i18n.language === "en"
                            ? sector?.attributes?.name
                            : sector?.attributes?.name_ar}
                        </h3>
                      </SectorItem>
                    ))
                  )}
                </SectorsContainer>
              </div>
            </Section>

            <Section style={{ marginTop: "20px" }}>
              {" "}
              <SectionTitle>
                {translations[i18n.language].companies}
              </SectionTitle>
              {/* Assuming you have an image (map) */}
              <div style={{ width: "100%", display: "block" }}>
                <Map height={window.innerWidth <= 768 ? "300px" : "732px"} />
              </div>
            </Section>
          </Content>
        </Wrapper>
      )}
    </>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  /* Add any additional global styles here */
`;

const Hero = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 20px;
  background-image: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.72) 0%,
      rgba(0, 0, 0, 0.35) 99.99%,
      #000 100%
    ),
    url("${({ image }) => process.env.REACT_APP_BACKEND + image}");
  background-size: cover; /* Use 'cover' to make sure the image covers the container */
  background-position: center;
  position: relative;
  font-family: "ABeeZee", sans-serif !important;
  max-width: 100%;
  max-height: 100vh; /* Set a maximum height to prevent the image from exceeding the screen */
  overflow: hidden; /* Hide any overflowing content */
  padding: 0 72px;
  /* Add mobile styles here */

  @media (max-width: 1000px) {
    padding: 0 24px;
  }
`;

const Title = styled.h1`
  font-size: 56px;
  font-style: normal;
  font-weight: 500;
  line-height: 123.023%; /* 68.893px */
  text-transform: capitalize;
  color: ${theme.white};
  width: 60%;
  margin-top: 28%;
  text-align: center;

  @media (max-width: 768px) {
    width: 90%;
    margin-top: 80% !important;
  }
`;

const Content = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 0 72px;
  @media (max-width: 768px) {
    padding: 0 24px;
  }
`;

const Section = styled.div`
  margin-bottom: 50px;
  max-width: 100%; /* Ensure content doesn't exceed screen width */

  background-color: #fcfcfc;
  padding: 20px;

  @media (max-width: 768px) {
    padding: 0 24px;
    margin-top: 20px;
  }
`;

// Adjust other styled components similarly for responsiveness.

const SectionOne = styled.div`
  margin-bottom: 50px;
  color: ${theme.white};
  background-repeat: no-repeat;
  background-size: contain;

  z-index: 3;
  margin: 30px -30px;
  width: ${(props) => (props.language === "en" ? "100%" : "100%")};
  justify-content: center;
  padding: 0px;

  @media (max-width: 768px) {
    margin: 10px auto;
    padding: ${(props) =>
      props.language === "en" ? "17px 30px" : "88px 5px 100px 0"};
  }
`;
const About = styled.div`
  display: flex;
  flex-direction: column;
  padding: 40px;

  @media (max-width: 768px) {
    flex-direction: column;
    padding: 35px;
  }
  }
`;
const SectionTitle = styled.h2`
  margin-bottom: 20px;
  font-family: "ABeeZee", sans-serif !important;
  color: ${theme.green};
  flex: 2;
  font-size: 40px;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.338px;
  text-transform: capitalize;
  font-style: italic;
  @media (max-width: 768px) {
    margin-bottom: 0;
    font-size: 22px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.338px;
    text-transform: capitalize;
  }
`;

const SectionContent = styled.div`
  margin-left: ${(props) => (props.language === "en" ? "0" : "50px")};

  color: ${theme.grey};
  line-height: 2;
  flex: 4;
  font-size: 20px;
  font-weight: 400;
  line-height: 164.523%; /* 32.905px */
  text-transform: capitalize;
  a {
    color: #78bc21;
  }
  @media (max-width: 768px) {
    font-size: 14px;
    font-size: 15px;
    font-weight: 400;
    line-height: 134.523%; /* 24.678px */
  }
`;

const Scroll = styled.div`
  color: ${theme.white};
  font-size: 12px;
  display: flex;
  flex-direction: row;
  align-items: center;
  @media (max-width: 768px) {
    display: none;
  }
`;
const Sections = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  // margin-bottom: 20px;
  width: 100%;
  margin-left: 56.49px;
  flex: 9;
  @media (max-width: 768px) {
    flex-direction: column;
    margin-left: 0;
  }
`;

const Button = styled.div`
  background-color: transparent;
  padding: 8px 16px; /* Adjust the padding to reduce the height */
  color: ${theme.white};
  // border-radius: 22px;
  margin-left: 10px;
  border: 1px solid ${theme.primary};
  cursor: pointer;
  display: flex;
  align-items: center; /* Align items vertically */
  justify-content: space-between; /* Align items horizontally */
  margin: 10px;
  h5 {
    margin: 0; /* Remove the margin-bottom */
    margin-left: 8px;
    margin-right: 8px;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 1px;
  }

  svg {
    margin-left: 8px;
    margin-right: 8px;
  }
`;

const SectorsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  flex: 1;
  padding: 20px;
  align-items: flex-end;
`;

const SectorItem = styled.div`
  flex: 0 0 calc(25% - 15px); /* Four items per row with spacing between */
  margin-bottom: 20px;
  text-align: center;

  @media (max-width: 768px) {
    flex: 0 0 calc(50% - 10px); /* Two items per row for smaller screens */
  }
`;

export default Dashboard;
